import phones from '@/constants/phones';

export const request = (type) => ({
  title: 'Are you sure you want to cancel this Service Call request?',
  text: 'By cancelling, the information you provided in the previous screens will be lost. Are you sure you want to proceed?',
  labelledBy: `${type}-cancellation-confirmation`,
  buttons: ['Go back', 'Cancel'],
});

export const job = (type) => ({
  title: 'Are you sure you want to cancel this Service Call?',
  text: 'By cancelling, the information you provided in the previous screens will be lost. Your device issue will not be resolved and your Service Call fee will be refunded via the payment method provided. Are you sure you want to proceed with cancellation?',
  labelledBy: `cancel-selected-${type}-job`,
  buttons: ['Go Back', 'Cancel'],
});

export const buyout = (type) => ({
  title: 'Are you sure you want to decline the Buyout option?',
  text: `
    If you decline, your Service Call will be cancelled and your Service Call fee will not be refunded.
    For more information, please check your Service Agreement.
    If you have any additional questions, feel free to call HomeServe at ${phones.contactCenter}.
  `,
  labelledBy: `${type}-buyout-cancellation-confirmation`,
  buttons: ['Proceed with Buyout', 'Decline Buyout'],
});

export default {
  request,
  job,
  buyout,
};
