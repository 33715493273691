<template>
  <ClaimCreate :cancellation-popup-data="cancellationPopupData">
    <component :is="currentView"></component>
  </ClaimCreate>
</template>

<script>
import ClaimCreate from '@/components/shared/ClaimCreate';
import ReimbursementExplanation from '@/components/claim/components/reimbursement/ReimbursementExplanation';
import cancellationPopupData from '@/constants/reimbursement/cancellation_popup_data';

export default {
  components: {
    ClaimCreate,
    ReimbursementExplanation,
  },
  computed: {
    currentView() {
      return 'ReimbursementExplanation';
    },
    cancellationPopupData() {
      return cancellationPopupData.request;
    },
  },
};
</script>
