<template>
  <div class="claim-explanation">
    <hs-heading>
      <p>{{ title }}</p>
      <p>{{ nextSteps }}</p>
    </hs-heading>
    <ul class="claim-explanation__list">
      <li
        v-for="item in items"
        :key="item.title"
        class="claim-explanation__list-item"
        :style="getAdditionalStyles(item)"
      >
        <span
          :class="[`claim-explanation__list-icon--${item.icon}`]"
          class="claim-explanation__list-icon"
        ></span>
        <section
          class="claim-explanation__list-section"
          :class="{'claim-explanation__list-section--center': !item.text}"
        >
          <h2 class="claim-explanation__list-title">{{ item.title }}</h2>
          <p
            v-if="item.text"
            class="claim-explanation__list-text"
            v-html="typeof item.text === 'function' ? item.text(serviceCallFee) : item.text"
          ></p>
        </section>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ClaimExplanation',
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    nextSteps: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('requestService', ['serviceCallFee']),
  },
  methods: {
    getAdditionalStyles(settings) {
      const styles = {};

      const {reducedMargin} = settings;
      if (reducedMargin) {
        styles.marginBottom = '12px';
      }

      return styles;
    },
  },
};
</script>

<style lang="scss" scoped>
.claim-explanation {
  padding-bottom: 130px;

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
  }
  &__content-wrapper {
    width: 100%;
    max-width: 730px;
  }
  &__list {
    max-width: 670px;
    margin-top: 25px;
    list-style-type: none;

    @include mf($gridMDBreakpoint) {
      margin-top: 48px;
    }
  }
  &__list-item {
    display: flex;
    width: 100%;
    font-size: 16px;
    &:not(:last-of-type) {
      margin-bottom: 30px;

      @include mf($gridMDBreakpoint) {
        margin-bottom: 24px;
      }
    }
  }
  &__list-icon {
    display: none;
    flex-shrink: 0;
    width: 69px;
    height: 69px;
    margin-right: 23px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;

    @include mf($gridMDBreakpoint) {
      display: block;
    }
    &--location {
      background-image: url('~@/assets/images/claim/location.svg');
    }
    &--payment {
      background-image: url('~@/assets/images/claim/payment.svg');
    }
    &--repair {
      background-image: url('~@/assets/images/claim/repair.svg');
    }
    &--notification {
      background-image: url('~@/assets/images/claim/notification.svg');
    }
    &--delivery-box {
      background-image: url('~@/assets/images/claim/delivery-box.svg');
    }
    &--confirm {
      background-image: url('~@/assets/images/claim/confirm.svg');
    }
    &--receipt {
      background-image: url('~@/assets/images/claim/receipt.svg');
    }
    &--happy-face {
      background-image: url('~@/assets/images/claim/happy-face.svg');
    }
    &--list-check {
      background-image: url('~@/assets/images/claim/list-check.svg');
    }
    &--phone-call {
      background-image: url('~@/assets/images/claim/phone-call.svg');
    }
    &--location-clock {
      background-image: url('~@/assets/images/claim/location-clock.svg');
    }
  }
  &__list-section {
    flex-grow: 1;
    &--center {
      display: grid;
      align-items: center;
    }
  }
  &__list-title {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: inherit;
  }
  &__list-text {
    color: $darkGray;
  }
  &__select-btn-cont {
    margin-top: 0;

    @include mf($gridMDBreakpoint) {
      margin-top: 50px;
    }
  }
}
</style>
