import {request, job, buyout} from '@/constants/claims/cancellation_popup_data';

const type = 'carry-in-repair';
const data = {
  request: request(type),
  job: job(type),
  buyout: buyout(type),
};

export default data;
