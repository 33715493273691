<template>
  <ClaimExplanationPage :texts="texts" @confirm="goToPhoneStep" />
</template>

<script>
import ClaimExplanationPage from '@/components/shared/ClaimExplanationPage';
import texts from '@/constants/home-service/explanation';

export default {
  components: {
    ClaimExplanationPage,
  },
  computed: {
    texts() {
      return texts;
    },
  },
  methods: {
    goToPhoneStep() {
      const {params, query} = this.$route;
      this.$router.push({
        params,
        query: {...query, step: 'phone'},
      });
    },
  },
};
</script>
