import toHtmlForProps from '@/utils/toHtmlForProps';
import {request, job, buyout} from '@/constants/claims/cancellation_popup_data';

const type = 'home-service';
const data = {
  request: request(type),
  job: job(type),
  buyout: buyout(type),
};

toHtmlForProps(data, 'text');
export default data;
