<template>
  <div>
    <wrapper-with-close-btn
      class="claim-create"
      :prevent-navigation="true"
      @back="showCancellationConfirmation"
    >
      <slot></slot>
    </wrapper-with-close-btn>
    <CancellationPopup
      v-if="isCancellationConfirmationVisible"
      v-bind="cancellationPopupData"
      @hide="hideCancellationConfirmation"
      @confirm="cancelRepair"
    />
  </div>
</template>
<script>
import CancellationPopup from '@/components/shared/CancellationPopup';

export default {
  props: {
    cancellationPopupData: {
      required: true,
    },
  },
  components: {
    CancellationPopup,
  },
  provide() {
    return {
      showClaimFlomCancellationConfirmation: () => this.showCancellationConfirmation(),
    };
  },
  data() {
    return {
      isCancellationConfirmationVisible: false,
    };
  },
  methods: {
    cancelRepair() {
      const {params, query} = this.$route;
      const {id} = params;
      this.$router.push({
        name: 'StartAClaim',
        params: {id},
        query,
      });
    },
    showCancellationConfirmation() {
      this.isCancellationConfirmationVisible = true;
    },
    hideCancellationConfirmation() {
      this.isCancellationConfirmationVisible = false;
    },
  },
  created() {
    const {query} = this.$route;
    if (!query || !Object.keys(query).length) {
      this.$router.replace({
        query: {...query, step: 'explanation'},
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.claim-create {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 30px;

  @include mf($gridMDBreakpoint) {
    margin-top: 60px;
  }
}
</style>
