<template>
  <ClaimCreate :cancellation-popup-data="cancellationPopupData">
    <component :is="currentView"></component>
  </ClaimCreate>
</template>

<script>
import ClaimCreate from '@/components/shared/ClaimCreate';
import ReplacementExplanation from '@/components/claim/components/replacement/ReplacementExplanation';
import cancellationPopupData from '@/constants/replacement/cancellation_popup_data';

export default {
  components: {
    ClaimCreate,
    ReplacementExplanation,
  },
  computed: {
    currentView() {
      return 'ReplacementExplanation';
    },
    cancellationPopupData() {
      return cancellationPopupData.request;
    },
  },
};
</script>
