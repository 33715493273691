import toHtmlForProps from '@/utils/toHtmlForProps';

const explanation = {
  title: 'We see you’ve decided to continue with Replacement.',
  nextSteps: 'Here are the next steps:',
  items: [
    {
      icon: 'confirm',
      title: 'Step 1: Confirm Replacement',
      text: 'Confirm that you would like to proceed with Replacement. Please refer to your Service Agreement for details.',
    },
    {
      icon: 'notification',
      title: 'Step 2: Get notification with replacement option',
      text: "You'll be notified here and via email/SMS with available replacement option. You have 24 hours to review and select a replacement option.",
    },
    {
      icon: 'payment',
      title: 'Step 3: Pay Service Call fee.',
      text: (fee) =>
        `Once you have agreed to continue with Replacement, a${
          ' $' + fee
        } Service Call fee will be required.`,
    },
    {
      icon: 'list-check',
      title: 'Step 4: Receive confirmation and shipping details',
      text: "You'll receive confirmation of the replacement and shipping details for delivery to the confirmed address.",
    },
    {
      icon: 'happy-face',
      title: 'Step 5: Track shipment',
      text: "You'll be able to track shipping progress here. Please note that your device being replaced will no longer be eligible for any coverage.  Once you receive the new device, we recommend that you register it for coverage.",
    },
  ],
  confirmButtonText: 'Select Replacement',
};

toHtmlForProps(explanation, 'text');
export default explanation;
