export default {
  title: 'Great! Now that you’ve decided to continue with Home Service,',
  nextSteps: 'here are the next steps:',
  items: [
    {
      icon: 'location-clock',
      title: 'Step 1: Set up a diagnostic call with a technician',
      text: 'Provide your contact information and indicate the preferred date for the technician’s call.',
    },
    {
      icon: 'payment',
      title: 'Step 2: Pay Service Call fee ',
      text: (fee) =>
        `Once you have provided your contact information and preferred date, a${
          ' $' + fee
        } Service Call fee will be required.`,
    },
    {
      icon: 'phone-call',
      title: 'Step 3: The technician will call you to learn about the problem',
      text: 'The technician will attempt to diagnose and troubleshoot the problem during the call. If the problem can be solved over the phone, there will be no need for an in-home visit.',
    },
    {
      icon: 'repair',
      title: 'Step 4: In-home appointment is set up ',
      text: 'If the problem requires an in-home visit, the technician will work with you to schedule an appointment.',
    },
  ],
  confirmButtonText: 'Next',
};
