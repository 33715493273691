import toHtmlForProps from '@/utils/toHtmlForProps';

const explanation = {
  title: 'We see you’ve decided to continue with Reimbursement.',
  nextSteps: 'Here are the next steps:',
  items: [
    {
      icon: 'confirm',
      title: 'Step 1: Confirm Reimbursement selection',
      text: 'Confirm that you would like to proceed with Reimbursement. Please refer to your Service Agreement for details.',
    },
    {
      icon: 'repair',
      title: 'Step 2: Perform repair',
      text: 'Choose a service provider for repairing your device.',
    },
    {
      icon: 'receipt',
      title: 'Step 3: Upload or email us your repair receipt',
      text: 'Please provide a copy of repair receipt(s). You can upload it here or send it via email. Receipt(s) must include the name, address and telephone number of the repair service provider, as well as a description of charges.',
      reducedMargin: true,
    },
    {
      icon: 'happy-face',
      title: 'Step 4: Receive reimbursement payment ',
      text: 'Once HomeServe obtains and reviews the receipt(s), your payment will take up to 30 days to be processed and sent to you.',
    },
  ],
  confirmButtonText: 'Confirm Reimbursement',
};

toHtmlForProps(explanation, 'text');
export default explanation;
