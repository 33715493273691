<template>
  <div class="home-service-time-picker">
    <div
      v-for="slot in filteredSlots"
      :key="slot.date"
      class="home-service-time-picker__slot-group"
    >
      <div class="home-service-time-picker__slot-group-title">
        {{ formattedMonthAndDay(slot.date) }}
      </div>
      <div
        class="home-service-time-picker__slot"
        tabindex="0"
        v-for="timeSlot in slot.slots"
        :class="slotModifiers(timeSlot, slot.date)"
        :key="timeSlot.slotCode"
        @keydown.space.prevent="emit(timeSlot, slot.date)"
        @keydown.enter.prevent="emit(timeSlot, slot.date)"
        @click="emit(timeSlot, slot.date)"
      >
        {{ formattedTime(timeSlot.startTimeval) }} - {{ formattedTime(timeSlot.endTimeval) }}
      </div>
    </div>
  </div>
</template>
<script>
import {formattedHours, formattedMonthAndDay} from '@/utils/dateUtils';

export default {
  props: {
    slots: {
      type: Array,
      default: () => [],
    },
    activeDate: {
      type: String,
      default: '',
    },
    chosenSlotCode: {
      type: String,
      default: '',
    },
  },
  methods: {
    isSelectedSlot(slot, date) {
      const {chosenSlotCode, activeDate} = this;
      return slot.slotCode === chosenSlotCode && activeDate === date;
    },
    slotModifiers(slot, date) {
      const modifiers = [];

      if (slot?.isActive !== 'true') {
        modifiers.push('disabled');
      }

      if (this.isSelectedSlot(slot, date)) {
        modifiers.push('selected');
      }

      const prefix = 'home-service-time-picker__slot';

      return modifiers.map((modifier) => `${prefix}--${modifier}`);
    },
    emit(slot, date) {
      if (!this.disabled && slot.isActive === 'true') {
        const fromFormatted = this.formattedTime(slot.startTimeval);
        const toFormatted = this.formattedTime(slot.endTimeval);
        const dateFormatted = this.formattedMonthAndDay(date);
        this.$emit('input', {
          ...slot,
          date,
          dateFormatted,
          fromFormatted,
          toFormatted,
        });
      }
    },
    formattedTime(time) {
      const [hours] = time.split(':');
      return formattedHours(parseInt(hours));
    },
    formattedMonthAndDay,
  },
  computed: {
    filteredSlots() {
      return this.slots?.filter((s) => s.slots.some((e) => e.isActive === 'true'));
    },
  },
};
</script>
<style lang="scss">
.home-service-time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: auto;
  padding: 0;
  padding-bottom: 35px;
  border: none;
  border-radius: 4px;

  @include mf($gridMDBreakpoint) {
    flex-direction: row;
    gap: 40px;
    min-width: 642px;
    padding: 30px 30px 35px;
    border: 1px solid $lightGray;
  }

  &__slot-group {
    width: 100%;
    border-top: 1px solid $lightGray;

    @include mf($gridMDBreakpoint) {
      border-top: none;
    }
  }
  &__slot-group-title {
    margin-top: 16px;
    font-weight: bold;
    font-size: 18px;

    @include mf($gridMDBreakpoint) {
      margin-top: 0;
      font-size: 20px;
    }
  }
  &__slot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    margin: 15px 0;
    color: $blue;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid $blue;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    &--disabled {
      cursor: default;
      opacity: 0.3;
    }

    @include mf($gridMDBreakpoint) {
      width: 162px;
      height: 30px;
    }
    &--selected {
      color: $white;
      background-color: $blue;
    }
    &:focus {
      outline: 1px solid $blue;
    }
  }
  &__slot-text {
    font-weight: 700;
  }
}
</style>
