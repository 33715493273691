<template>
  <ClaimCreate :cancellation-popup-data="cancellationPopupData">
    <component :is="currentView"></component>
  </ClaimCreate>
</template>

<script>
import ClaimCreate from '@/components/shared/ClaimCreate';
import cancellationPopupData from '@/constants/carry-in/cancellation_popup_data';
import CarryInRepairExplanation from '@/components/claim/components/carry-in-repair/CarryInRepairExplanation';
import CarryInRepairFindLocation from '@/components/claim/components/carry-in-repair/CarryInRepairFindLocation';
import CarryInRepairChooseDate from '@/components/claim/components/carry-in-repair/CarryInRepairChooseDate';

export default {
  components: {
    ClaimCreate,
    CarryInRepairExplanation,
    CarryInRepairFindLocation,
    CarryInRepairChooseDate,
  },
  computed: {
    currentView() {
      switch (this.$route.query.step) {
        case 'explanation':
          return 'CarryInRepairExplanation';
        case 'location':
          return 'CarryInRepairFindLocation';
        case 'date':
          return 'CarryInRepairChooseDate';
      }
      return '';
    },
    cancellationPopupData() {
      return cancellationPopupData.request;
    },
  },
};
</script>
