<template>
  <hs-popup
    class="cancellation-confirmation"
    :aria-labelledby="labelledBy"
    :aria-describedby="describedBy"
    :decline-button-text="declineButtonText"
    :confirm-button-text="confirmButtonText"
    :disabled="disabled"
    :decline-disabled="declineDisabled"
    v-on="$listeners"
  >
    <h1 class="hs-popup__title" :id="labelledBy">
      {{ title }}
    </h1>
    <p
      class="cancellation-confirmation__text"
      :aria-describedby="describedBy"
      v-html="typeof text === 'function' ? text() : text"
    ></p>
  </hs-popup>
</template>

<script>
export default {
  props: {
    labelledBy: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: [String, Function],
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    declineDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    describedBy() {
      const {labelledBy} = this;
      return `${labelledBy}-description`;
    },
    declineButtonText() {
      const {buttons} = this;
      return buttons.length === 2 ? buttons[0] : null;
    },
    confirmButtonText() {
      const {buttons} = this;
      return buttons.length === 1 ? buttons[0] : buttons[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.cancellation-confirmation {
  font-size: 18px;
  &__text {
    padding-top: 30px;
    color: $darkGray;
    line-height: 160%;
  }
}
</style>
