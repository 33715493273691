import toHtmlForProps from '@/utils/toHtmlForProps';
import {request, job} from '@/constants/claims/cancellation_popup_data';

const type = 'reimbursement';
const data = {
  request: request(type),
  job: job(type),
};

toHtmlForProps(data, 'text');
export default data;
