<template>
  <div class="claim-explanation-page">
    <ClaimExplanation v-bind="texts" />

    <StepNavigationButtons
      @back="goBack"
      @confirm="$emit('confirm')"
      :disabled="disabled"
      :disabled-back="disabledBack"
      :confirm-button-text="texts.confirmButtonText"
      :mobile-button-width="mobileButtonWidth"
    />
  </div>
</template>

<script>
import ClaimExplanation from '@/components/shared/ClaimExplanation';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';

export default {
  props: {
    texts: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledBack: {
      type: Boolean,
      default: false,
    },
    mobileButtonWidth: {
      type: Number,
    },
  },
  inject: ['showClaimFlomCancellationConfirmation'],
  components: {
    ClaimExplanation,
    StepNavigationButtons,
  },
  methods: {
    goBack() {
      this.showClaimFlomCancellationConfirmation();
    },
  },
};
</script>

<style lang="scss" scoped>
.claim-explanation-page {
  padding-bottom: 50px;

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
  }

  &__select-btn-cont {
    margin-top: 0;

    @include mf($gridMDBreakpoint) {
      margin-top: 50px;
    }
  }
}
</style>
