<template>
  <ClaimCreate :cancellation-popup-data="cancellationPopupData">
    <component :is="currentView"></component>
  </ClaimCreate>
</template>

<script>
import ClaimCreate from '@/components/shared/ClaimCreate';
import HomeServiceExplanation from '@/components/claim/components/home-service/HomeServiceExplanation';
import HomeServicePhone from '@/components/claim/components/home-service/HomeServicePhone';
import HomeServiceDateAndTime from '@/components/claim/components/home-service/HomeServiceDateAndTime';
import cancellationPopupData from '@/constants/home-service/cancellation_popup_data';

export default {
  components: {
    ClaimCreate,
    HomeServiceExplanation,
    HomeServicePhone,
    HomeServiceDateAndTime,
  },
  computed: {
    currentView() {
      switch (this.$route.query.step) {
        case 'explanation':
          return 'HomeServiceExplanation';
        case 'phone':
          return 'HomeServicePhone';
        case 'date-and-time':
          return 'HomeServiceDateAndTime';
      }
      return '';
    },
    cancellationPopupData() {
      return cancellationPopupData.request;
    },
  },
};
</script>
