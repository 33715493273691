<template>
  <ClaimExplanationPage :texts="texts" @confirm="goToLocationStep" />
</template>

<script>
import ClaimExplanationPage from '@/components/shared/ClaimExplanationPage';
import texts from '@/constants/carry-in/explanation';

export default {
  components: {
    ClaimExplanationPage,
  },
  computed: {
    texts() {
      return texts;
    },
  },
  methods: {
    goToLocationStep() {
      const {params, query} = this.$route;
      this.$router.push({
        name: 'StartSpecificClaim',
        params,
        query: {...query, step: 'location'},
      });
    },
  },
};
</script>
