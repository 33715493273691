<template>
  <div class="start-claim">
    <component :is="currentPage" />
    <PaymentLoader v-if="paymentLoader" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Reimbursement from '@/components/claim/pages/reimbursement/Create';
import CarryInRepair from '@/components/claim/pages/carry-in-repair/Create';
import DepotRepair from '@/components/claim/pages/depot-repair/Create';
import Replacement from '@/components/claim/pages/replacement/Create';
import HomeService from '@/components/claim/pages/home-service/Create';
import Buyout from '@/components/claim/pages/buyout/Create';
import PaymentLoader from '@/components/claim/ClaimPaymentLoaderModal';

export default {
  components: {
    Reimbursement,
    CarryInRepair,
    DepotRepair,
    Replacement,
    HomeService,
    Buyout,
    PaymentLoader,
  },
  computed: {
    ...mapState('requestService', ['paymentLoader']),
    currentPage() {
      switch (this.$route.params.type) {
        case 'claim_self_repair':
          return 'Reimbursement';
        case 'claim_carryin':
          return 'CarryInRepair';
        case 'claim_dropoff':
          return 'DepotRepair';
        case 'replacement':
          return 'Replacement';
        case 'claim_onsite':
          return 'HomeService';
        case 'reimbursement':
          return 'Buyout';
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.start-claim {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
