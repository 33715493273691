export default {
  title: 'We see you’ve decided to continue with Carry-In Service.',
  nextSteps: 'Here are the next steps:',
  items: [
    {
      icon: 'location',
      title: 'Step 1: Select service center location and date to drop off your device.',
      text: `
        Based on your zip code, we offer nearby service center location(s).
        Please select one location and a drop off date that work best based on your availability.
      `,
    },
    {
      icon: 'payment',
      title: 'Step 2: Pay Service Call fee.',
      text: (fee) =>
        `Once you have selected your location and date, a${
          ' $' + fee
        } Service Call fee will be required.`,
    },
    {
      icon: 'repair',
      title: 'Step 3: Bring your device to service center location.',
      text: 'On the date you have chosen, please bring your device to service center location for us to fix it. Our technician will provide you with an estimated time for repair completion.',
    },
    {
      icon: 'notification',
      title: 'Step 4: Get repair process notifications and updates.',
      text: 'Repair updates will be displayed here. You’ll also receive SMS messages and emails.',
    },
    {
      icon: 'happy-face',
      title: 'Step 5: Pick up your repaired device.',
      text: 'Once your device repair is completed, you can pick it up at the location and enjoy using it again.',
    },
  ],
  confirmButtonText: 'Find Location',
};
