<template>
  <StatusPage
    class="buyout-create"
    status="success"
    title="Good news! You've successfully set up your Service Call for Buyout"
    restrict-title-width
  >
    <p class="buyout-create__text" v-if="showBuyoutText">
      We will pay you ${{ buyoutCost }} based on the fair market value of your device at the time
      the Service Call was made up to the benefit limit amount minus the Service Call fee. Payment
      processing and delivery will take up to 30 days.
      <br />
      <strong
        >Note: Your device will be automatically removed from your device list. Go to Service
        History to check on your Service Call progress.</strong
      >
    </p>
    <div class="buyout-create__text buyout-create__text--loader" v-else>
      <content-placeholders :centered="true">
        <content-placeholders-text :lines="4" />
      </content-placeholders>
    </div>
    <GoToMyDevicesButton class="buyout-create__action-button" />
  </StatusPage>
</template>
<script>
import StatusPage from '@/components/shared/StatusPage';
import GoToMyDevicesButton from '@/components/shared/GoToMyDevicesButton';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      loadingInProgress: true,
      seekingJob: null,
    };
  },
  components: {
    StatusPage,
    GoToMyDevicesButton,
  },
  computed: {
    ...mapGetters('jobInfo', ['getByDeviceId']),
    deviceId() {
      return this.$route.params.id;
    },
    showBuyoutText() {
      return !this.loadingInProgress && this.buyoutCost;
    },
    isBuyoutClaim() {
      if (!this.seekingJob) {
        return false;
      }
      if (!this.seekingJob.serviceJobType || !this.seekingJob.serviceJobID) {
        return false;
      }
      // Check if claim type is Buyout and claim id is HS.
      // Make sure that statement is consistent.
      return (
        this.seekingJob.serviceJobType.toLowerCase().indexOf('buyout') !== -1 &&
        this.seekingJob.serviceJobID.toLowerCase().startsWith('hscm')
      );
    },
    buyoutCost() {
      if (!this.seekingJob) {
        return null;
      }

      return parseInt(this.seekingJob.claimDetails.buyoutAmount, 10);
    },
  },
  watch: {
    deviceId: {
      async handler(id) {
        const {customerRef} = this.$route.query;

        const resp = await this.$store.dispatch('user/DECRYPT_PARAMS', {
          customerRef,
        });

        const claimsList = await this.$store.dispatch('jobInfo/GET_CLAIMS_LIST', {
          customerRef: resp?.customerRef,
        });

        this.seekingJob = this.getByDeviceId(this.deviceId);

        if (this.isBuyoutClaim) {
          await this.$store.dispatch('jobInfo/GET_BUYOUT_AMOUNT', {
            serviceJobId: this.seekingJob.serviceJobID,
          });
          this.loadingInProgress = false;
        } else {
          this.loadingInProgress = false;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.buyout-create {
  &__text {
    max-width: 710px;
    margin-bottom: 38px;

    @include mf($gridMDBreakpoint) {
      &--loader {
        width: 710px;
      }
    }
  }
  &__action-button {
    margin-bottom: 30px;
  }
}
</style>
