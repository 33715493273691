import { render, staticRenderFns } from "./ClaimPaymentLoaderModal.vue?vue&type=template&id=109a5c00&scoped=true&"
var script = {}
import style0 from "./ClaimPaymentLoaderModal.vue?vue&type=style&index=0&id=109a5c00&scoped=true&lang=scss&"
import style1 from "./ClaimPaymentLoaderModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "109a5c00",
  null
  
)

export default component.exports