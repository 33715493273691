<template>
  <ClaimCreate :cancellation-popup-data="cancellationPopupData">
    <component :is="currentView"></component>
  </ClaimCreate>
</template>
<script>
import ClaimCreate from '@/components/shared/ClaimCreate';
import DepotRepairExplanation from '@/components/claim/components/depot-repair/DepotRepairExplanation';
import DepotRepairFindLocation from '@/components/claim/components/depot-repair/DepotRepairFindLocation';
import cancellationPopupData from '@/constants/depot/cancellation_popup_data';

export default {
  components: {
    ClaimCreate,
    DepotRepairExplanation,
    DepotRepairFindLocation,
  },
  computed: {
    currentView() {
      switch (this.$route.query.step) {
        case 'explanation':
          return 'DepotRepairExplanation';
        case 'location':
          return 'DepotRepairFindLocation';
      }
      return 'DepotRepairExplanation';
    },
    cancellationPopupData() {
      return cancellationPopupData.request;
    },
  },
};
</script>
