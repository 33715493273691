<template>
  <div class="find-location">
    <claim-location-form
      :radios="radios"
      :chosen-location="chosenLocation"
      :zip="zipcode"
      title="Please select a shipping carrier location:"
      @change="setLocation"
    >
      <StepNavigationButtons
        @back="goBack"
        @confirm="createClaim"
        :disabled="!chosenLocation || paymentLoader"
        one-more-step
        confirm-button-text="Proceed to Payment"
      />
    </claim-location-form>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import ClaimLocationForm from '@/components/shared/ClaimLocationForm';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import createJobMixin from '@/mixins/createJobMixin';
import {getLocationAddress} from '@/utils/location';

export default {
  components: {
    ClaimLocationForm,
    StepNavigationButtons,
  },
  mixins: [createJobMixin],
  data() {
    return {
      serviceTypeCode: 'CLAIM_DROPOFF',
      chosenDropOffLocation: null,
      chosenLocation: '',
    };
  },
  methods: {
    ...mapMutations('requestService', ['SET_PAYMENT_PROGRESS']),
    setLocation(l) {
      this.chosenLocation = l;
      this.chosenDropOffLocation = this.service?.dropOffLocations.find(
        (item) => item.dropOffLocationCode === l
      );
    },
    async createClaim() {
      this.SET_PAYMENT_PROGRESS(true);

      const {serviceTypeCode} = this;
      const {vendorCode, serviceLocation} = this.service;
      const dropOffLocation = this.chosenDropOffLocation;
      const {dropOffLocationCode, dropOffLocationName} = dropOffLocation;
      const dropOffLocationAddress = dropOffLocation?.address;
      const dropOffLocationZipcode = dropOffLocation?.pinCode;
      const dropOffLocationCity = dropOffLocation?.city;
      const dropOffLocationState = dropOffLocation?.state;

      const {paymentUrl} = await this.createJob({
        serviceTypeCode,
        successRouteName: 'DepotRepairSuccess',

        consumerServiceRequestParams: {
          vendorCode,

          // TODO: Here is picked up first available service provider.
          serviceLocationCode: serviceLocation[0]?.serviceLocationCode,
          serviceLocationName: serviceLocation[0]?.serviceLocationName,
          dropOffLocationCode,
          dropOffLocationName,
          dropOffLocationAddress,
          dropOffLocationZipcode,
          dropOffLocationCity,
          dropOffLocationState,
        },
      });

      window.location = paymentUrl;
    },
    goBack() {
      const {params, query} = this.$route;
      this.$router.push({
        name: this.$route.name,
        params,
        query: {...query, step: 'explanation'},
      });
    },
  },
  computed: {
    ...mapState('requestService', ['paymentLoader']),
    ...mapGetters('requestService', ['zipcode', 'fullSelectedAddressText', 'availableServices']),
    service() {
      return this.availableServices[this.serviceTypeCode];
    },
    locations() {
      let locations = [];

      const dropOffLocations = this.service?.dropOffLocations;
      if (dropOffLocations) {
        locations = dropOffLocations.map((location) => ({
          id: location.dropOffLocationCode,
          text: location.dropOffLocationName,
          distance: 'Click to check', // TODO: Fix distance.
          from: this.fullSelectedAddressText,
          to: getLocationAddress(location),
        }));
      }

      return locations;
    },
    radios() {
      return {
        name: 'depot-location',
        list: this.locations,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.find-location {
  padding-bottom: 220px;

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
  }
}
</style>
