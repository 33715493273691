export function getLocationAddress(location) {
  let locationAddress = '';
  const {address, city, state} = location;
  if (address) {
    locationAddress += address;
  }
  if (city) {
    locationAddress += `, ${city}`;
  }
  if (state) {
    locationAddress += `, ${state}`;
  }
  return locationAddress;
}
