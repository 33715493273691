<template>
  <form class="home-service-phone" @submit.stop.prevent="goToDateAndTime">
    <hs-heading class="home-service-phone__title"
      >Please confirm phone number where a technician can reach you.</hs-heading
    >
    <div class="home-service-phone__icon-info-container">
      <DeviceIcon class="home-service-phone__device-icon" :icon="productLineName" />
      <div>
        <hs-label class="home-service-phone__label" for="home-service-phone-input"
          >Phone number</hs-label
        >
        <hs-input
          ref="phoneInputComponent"
          v-model="number"
          input-type="tel"
          class="home-service-phone__input"
          @focus.native="onFocus"
          @keypress.native="onKeypress"
          id="home-service-phone-input"
          placeholder="Phone Number"
        />

        <p class="home-service-phone__text">
          If this is not the best number to reach you at, please provide a new one.
        </p>
      </div>
    </div>
    <StepNavigationButtons
      confirm-button-text="Next"
      @back="goBack"
      :disabled="!isValid || getSlotsInProgress"
    />
  </form>
</template>
<script>
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex';
import {parsePhoneNumber, isValidPhoneNumber, AsYouType} from 'libphonenumber-js';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import DeviceIcon from '@/components/shared/DeviceIcon';

export default {
  components: {
    StepNavigationButtons,
    DeviceIcon,
  },

  async created() {
    await this.CLEAR_DATETIME();
  },

  data() {
    return {
      number: '',
    };
  },

  methods: {
    ...mapActions('requestService', ['GET_DEVICE_DETAILS']),
    ...mapActions('requestService/homeRepair', ['GET_SLOTS']),
    ...mapMutations('requestService/homeRepair', ['SET_PHONE_NUMBER', 'CLEAR_DATETIME']),

    onFocus() {
      const {number} = this;
      if (!number.trim().length) {
        this.number = '+1 ';
      }
    },

    onKeypress(e) {
      if (!e.ctrlKey && !e.metaKey && !e.altKey && e.key !== 'Enter') {
        const char = String.fromCharCode(e.keyCode);
        const isValidChar = /[0-9-+ ]/g.test(char);
        if (!isValidChar) {
          e.preventDefault();
        }
      }
    },

    goBack() {
      const {params, query} = this.$route;
      this.$router.push({
        params,
        query: {...query, step: 'explanation'},
      });
    },

    async goToDateAndTime() {
      const phoneNumber = parsePhoneNumber(this.number, 'US').number.replace('+1', '');
      this.SET_PHONE_NUMBER(phoneNumber);

      await this.GET_SLOTS();

      const {params, query} = this.$route;
      this.$router.push({
        params,
        query: {...query, step: 'date-and-time'},
      });
    },

    setNewNumber(newNumber) {
      if (newNumber && !this.number) {
        this.number = parsePhoneNumber(newNumber, 'US').formatNational();
      }
    },
  },

  computed: {
    ...mapGetters('user', ['mobileNumber']),
    ...mapState('deviceInformation', ['productLineName']),

    ...mapState('requestService/homeRepair', ['getSlotsInProgress']),
    ...mapState('requestService/homeRepair', {
      storedPhoneNumber: 'phoneNumber',
    }),
    isValid() {
      const {number} = this;
      let isValid = false;
      try {
        isValid = isValidPhoneNumber(number, 'US');
      } catch (e) {
        // do nothing
      }

      return isValid;
    },
  },

  watch: {
    storedPhoneNumber: {
      handler(newNumber) {
        this.setNewNumber(newNumber);
      },
      immediate: true,
    },
    mobileNumber: {
      handler(newNumber) {
        this.setNewNumber(newNumber);
      },
      immediate: true,
    },
    number: {
      async handler(newValue) {
        const phone = new AsYouType('US').input(newValue);
        if (phone !== newValue) {
          this.number = phone;

          const {phoneInputComponent} = this.$refs;
          if (phoneInputComponent) {
            const phoneInput = phoneInputComponent.$el.querySelector('input');

            let {selectionStart, selectionEnd} = phoneInput;
            const delta = phone.length - newValue.length;
            selectionStart += delta;
            selectionEnd += delta;

            await this.$nextTick();

            Object.assign(phoneInput, {selectionStart, selectionEnd});
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.home-service-phone {
  padding: 0 10px 190px;

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
  }
  &__title {
    @include mf($gridXSBreakpoint) {
      margin-bottom: 55px;
    }
  }
  &__icon-info-container {
    display: flex;
    gap: 7%;

    @include mf($gridMDBreakpoint) {
      gap: 70px;
    }
  }
  &__label {
    @include mf($gridXSBreakpoint) {
      margin-bottom: 0;
    }
  }
  &__input {
    max-width: 365px;
  }
  &__device-icon {
    display: none;
    width: 100px;
    height: 100px;

    @include mf($gridXSBreakpoint) {
      display: inline-block;
    }
  }
  &__text {
    margin: 18px 0;
    color: $darkGray;
    line-height: 160%;
  }
}
</style>
