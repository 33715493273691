import {request, job, buyout} from '@/constants/claims/cancellation_popup_data';

const type = 'replacement';
const data = {
  request: request(type),
  job: {
    title: 'Are you sure you want to cancel this Service Call?',
    text: 'By cancelling, the information you provided in the previous screens will be lost. Are you sure you want to proceed with cancellation?',
    labelledBy: `cancel-selected-${type}-job`,
    buttons: ['Go Back', 'Cancel'],
  },
  buyout: buyout(type),
};

export default data;
