<template>
  <div class="home-service-date-and-time">
    <div>
      <hs-heading>Please select your preferred date and time to talk to a technician</hs-heading>
    </div>
    <div class="home-service-date-and-time__container">
      <section>
        <div class="home-service-date-and-time__time-title">
          <span>Selected call time:</span>
          <span class="home-service-date-and-time__time-value">{{ selectedDateTime }}</span>
        </div>
        <HomeServiceTimePicker
          :slots="slots"
          :chosen-slot-code="chosenTimeSlotCode"
          :active-date="date"
          @input="onTimePickerChange"
        />
      </section>
    </div>

    <StepNavigationButtons
      @back="goBack"
      @confirm="createClaim"
      :disabled="!isValid || paymentLoader"
      one-more-step
      confirm-button-text="Proceed to Payment"
    />
  </div>
</template>
<script>
import {mapMutations, mapState, mapGetters} from 'vuex';
import HomeServiceTimePicker from './HomeServiceTimePicker';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import createJobMixin from '@/mixins/createJobMixin';

const state = {
  ...mapState('requestService/homeRepair', ['slots', 'date', 'chosenTimeSlotCode']),
  ...mapState('requestService', ['paymentLoader']),
};

const getters = {
  ...mapGetters('requestService/homeRepair', ['selectedSlot', 'initClaimPayloadParams']),
  ...mapGetters('requestService', ['availableServices']),
};

const mutations = {
  ...mapMutations('requestService/homeRepair', ['SET_CHOSEN_DATE', 'SET_CHOSEN_TIME_SLOT_CODE']),
  ...mapMutations('requestService', ['SET_INIT_CLAIM_IN_PROGRESS', 'SET_PAYMENT_PROGRESS']),
};

const actions = {};

export default {
  components: {
    HomeServiceTimePicker,
    StepNavigationButtons,
  },
  mixins: [createJobMixin],
  data() {
    return {
      fromFormatted: '',
      toFormatted: '',
      dateFormatted: '',
    };
  },
  created() {
    if (!this.slots) {
      const {params, query} = this.$route;
      delete query.step;

      this.$router.replace({
        name: 'StartAClaim',
        params,
        query,
      });
    }
  },
  methods: {
    ...mutations,
    ...actions,
    onDayChange(day) {
      this.SET_CHOSEN_TIME_SLOT_CODE(null);
      this.SET_CHOSEN_DATE(day);
    },
    onTimePickerChange({date, slotCode, fromFormatted, toFormatted, dateFormatted}) {
      Object.assign(this, {fromFormatted, toFormatted, dateFormatted});

      this.SET_CHOSEN_DATE(date);
      this.SET_CHOSEN_TIME_SLOT_CODE(slotCode);
    },
    goBack() {
      const {params, query} = this.$route;
      this.$router.push({
        params,
        query: {...query, step: 'phone'},
      });
    },
    async createClaim() {
      this.SET_PAYMENT_PROGRESS(true);
      const {initClaimPayloadParams} = this;
      const service = this.availableServices['CLAIM_ONSITE'];
      const {serviceLocation} = service;

      // TODO: Here is picked up first available service provider.
      initClaimPayloadParams.consumerServiceRequestParams.serviceLocationCode =
        serviceLocation[0]?.serviceLocationCode;
      initClaimPayloadParams.consumerServiceRequestParams.serviceLocationName =
        serviceLocation[0]?.serviceLocationName;

      const {paymentUrl} = await this.createJob({
        serviceTypeCode: 'CLAIM_ONSITE',
        successRouteName: 'HomeServiceSuccess',

        ...initClaimPayloadParams,
      });

      window.location = paymentUrl;
    },
  },
  computed: {
    ...state,
    ...getters,
    availableDates() {
      const {slots = {}} = this;
      return Object.values(slots).map((slot) => slot.date);
    },
    availableSlots() {
      const {selectedSlot} = this;
      return selectedSlot ? selectedSlot.slots.filter((slot) => slot.isActive === 'true') : [];
    },
    selectedDate() {
      if (this.date) {
        const dateArr = this.date.split('-');
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
      }
      return 'not selected';
    },
    selectedDateTime() {
      const {chosenTimeSlotCode, fromFormatted, toFormatted, dateFormatted} = this;
      return chosenTimeSlotCode
        ? `${dateFormatted}, ${fromFormatted} - ${toFormatted}`
        : 'not selected';
    },
    isValid() {
      return this.date && this.chosenTimeSlotCode;
    },
  },
};
</script>
<style lang="scss" scoped>
.home-service-date-and-time {
  padding-bottom: 180px;

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
  }
  &__text {
    color: $darkGray;
    line-height: 19px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;

    @include mf($gridMDBreakpoint) {
      flex-direction: row;
      align-items: unset;
      justify-content: unset;
    }
  }
  &__time-title {
    max-width: 550px;
    margin: auto;
    margin-bottom: 18px;
    font-weight: bold;
    font-size: 18px;
    text-align: left;

    @include mf($gridMDBreakpoint) {
      max-width: unset;
      font-size: 20px;
      text-align: unset;
    }
  }
  &__time-value {
    display: block;

    @include mf($gridMDBreakpoint) {
      display: inline-block;
      text-indent: 4px;
    }
  }
  &__time-picker {
    width: 100%;
    max-width: 440px;
  }
}
</style>
