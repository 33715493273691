<template>
  <ClaimExplanationPage
    :texts="texts"
    :disabled="disableButtons"
    :disabled-back="disableButtons"
    @confirm="createClaim"
  />
</template>

<script>
import {mapGetters} from 'vuex';
import ClaimExplanationPage from '@/components/shared/ClaimExplanationPage';
import texts from '@/constants/replacement/explanation';
import createJobMixin from '@/mixins/createJobMixin';

const getters = {
  ...mapGetters('requestService', ['availableServices']),
};

export default {
  components: {
    ClaimExplanationPage,
  },
  mixins: [createJobMixin],
  data() {
    return {
      disableButtons: false,
    };
  },
  methods: {
    async createClaim() {
      this.disableButtons = true;

      const service = this.availableServices['CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE'];
      const {serviceLocation} = service;

      // TODO: Here is picked up first available service provider.
      const serviceLocationCode = serviceLocation[0]?.serviceLocationCode;
      const serviceLocationName = serviceLocation[0]?.serviceLocationName;

      const {id} = await this.createJob({
        serviceTypeCode: 'CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE',
        successRouteName: 'ReplacementSuccess',

        consumerServiceRequestParams: {
          serviceLocationCode,
          serviceLocationName,
        },
      });

      let path = this.getRedirectFromPaymentUrl(id, 'ReplacementSuccess');
      path = path.replace(window.location.origin, '');

      this.$router.push({path});
    },
  },
  computed: {
    ...getters,
    texts() {
      return texts;
    },
  },
};
</script>
