export default {
  title: 'We see you’ve decided to continue with Depot Service.',
  nextSteps: 'Here are the next steps:',
  items: [
    {
      icon: 'location',
      title: 'Step 1: Select location to drop off your device',
      text: 'Based on your zip code, we have provided nearby shipping carrier location(s). Please select one location that works best for you.',
    },
    {
      icon: 'payment',
      title: 'Step 2: Pay Service Call fee',
      text: (fee) =>
        `Once you have selected your location, a${' $' + fee} Service Call fee will be required.`,
    },
    {
      icon: 'delivery-box',
      title: 'Step 3: Pack your device and affix the shipping label',
      text: 'We’ll provide you with a shipping label. Please print and affix it to the box. (Please use your own box for cell phones and for any other device, we’ll send you a packing box.)',
    },
    {
      icon: 'repair',
      title: 'Step 4: Drop off your device at the selected location',
    },
    {
      icon: 'notification',
      title:
        'Step 5: Once your device repair is completed, it will be sent back to you, so you can enjoy using it again',
      text: 'All repair updates will be displayed here and you’ll also receive a SMS or email.',
    },
  ],
  confirmButtonText: 'Find Location',
};
