<template>
  <ClaimExplanationPage
    :mobile-button-width="235"
    :texts="texts"
    :disabled="disableButtons"
    :disabled-back="disableButtons"
    @confirm="createClaim"
  />
</template>

<script>
import ClaimExplanationPage from '@/components/shared/ClaimExplanationPage';
import texts from '@/constants/reimbursement/explanation';
import createJobMixin from '@/mixins/createJobMixin';

export default {
  components: {
    ClaimExplanationPage,
  },
  mixins: [createJobMixin],
  data() {
    return {
      service: null,
      disableButtons: false,
    };
  },
  methods: {
    async createClaim() {
      this.disableButtons = true;
      const {id} = await this.createJob({
        serviceTypeCode: 'CLAIM_SELF_REPAIR',
        successRouteName: 'ReimbursementSuccess',
        disableServify: true,
      });

      let path = this.getRedirectFromPaymentUrl(id, 'ReimbursementSuccess');
      path = path.replace(window.location.origin, '');

      this.$router.push({path});
    },
  },
  computed: {
    texts() {
      return texts;
    },
  },
};
</script>

<style lang="scss" scoped>
.reimbursement-explanation {
  padding-bottom: 0;

  &__select-btn-cont {
    margin-top: 0;

    @include mf($gridMDBreakpoint) {
      margin-top: 50px;
    }
  }
}
</style>
